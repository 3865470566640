import React, { useEffect, useState } from 'react'
import Header from './Header'
import Homebody from './Homebody'
import { db } from './firebase'
import { useDispatch } from 'react-redux'
import { user } from './features/userSlice'

function Home() {

  const [info, setinfo] = useState(null)

  const dispatch = useDispatch()

  useEffect(()=>{
    db.collection('userchat').onSnapshot((snapshot)=>{
    setinfo( snapshot.docs.map((doc)=>({
      id: doc.id,
      data: doc.data(),
    })))
  })
  },[])

  useEffect(()=>{
    if(info){
      dispatch(
        user({
          info : info
        })
      )
    }
  },[dispatch, info])

  return (
    <div>
        <Header/>
       
          <Homebody/>
        
    </div>
  )
}

export default Home