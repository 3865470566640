import { Cancel, Edit, Error, Home, List, Translate } from '@mui/icons-material'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './home.css'
import { Avatar } from '@mui/material'
import './header.css'
import logo from './img/log.png'
import Translates from './Translate'

function Header() {

    const [open, setopen] = useState(false)
    const [ tr, settr] = useState(false)


    const menu=()=>{
       open ? setopen(false) : setopen(true)
       settr(false)
    }


    const trs =()=>{
        tr ? settr(false) : settr(true)
    }

  return (
    <div className='main_head'>  
    <div className='tophd1' >
        <div className='extrawrt'>
            <small> ▪ Faq </small>
            <small> ▪ Help Desk </small>
            <small> ▪ Track Shipment </small>
            <small> Mon - Sat9 am - 6 pm</small>
        </div>



    </div>
    <div className='header'>
        <div className='logo_bod'>
            <img src={logo} alt='/logo' />
            <p>Globalexpress</p>
        </div>

        <div className='links'>
        
            <Link to='/' className='link'>
                <p>HOME</p>
            </Link>
            <Link to='/about' className='link'>
                <p>ABOUT US</p>
            </Link>
            <Link to='/contact' className='link'>
                <p>CONTACT US</p>
            </Link>

        </div>

            <Translate onClick={trs} style={{marginRight:'1em', cursor:'pointer'}}/>

            <Link to='/request' className='request'>
                <p>REQUEST A QUOTE</p>
                <Edit/>
            </Link>
        </div>








        <div className='navbar'>
        <div className='logo_bod'>
            <img src={logo} alt='/logo' />
            <p>Globalexpress</p>
        </div>
            {
                !open?
                <div style={{ display:'flex', alignItems:'center'}} >
                    <div>
                    <Translate onClick={trs} style={{marginRight:'1em',  cursor:'pointer'}}/>
                    </div>
                    <div>
                    <List id='menu' onClick={menu}/>
                    </div>
                </div>
                : null
            }
            {
                open?
                    <>
                    <div className='nav_bod'>
                        <div className='cancle' onClick={menu}>
                            <Cancel id='cancel' style={{color:'black'}} />
                        </div>
                        <div className='nav_links'>
                            <Link to='/' className='navs' style={{color:'black'}}>
                                <Home/>
                                <p>HOME</p>
                            </Link>
                            <Link to='/about' className='navs' style={{color:'black'}}>
                                <Error/>
                                <p>ABOUT US</p>
                            </Link>
                            <Link to='/contact' className='navs' style={{color:'black'}}>
                                <Avatar id='avatar'/>
                                <p>CONTACT US</p>
                            </Link>
                            <Link to='/request' className='request' style={{color:'white'}}>
                                <p>REQUEST A QUOTE</p>
                                <Edit/>
                            </Link>
                        </div>
                    </div>
                    </>
                    : null
                }
        </div>

        {
            tr ? 
            <div style={{position:'absolute', bottom:'-4em', right:'1em'}}>
                <Translates/>
            </div>
        :
        null
        }


    </div>
  );
}

export default Header