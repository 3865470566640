import React from 'react'
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css';
import { Favorite, Person, Sms } from '@mui/icons-material';
import blog1 from './img/blog1.jpg'
import blog2 from './img/blog2.jpg'
import blog3 from './img/blog3.jpg'


 
function Slider() {

  const infos = [
    {
      t1: 'OCEAN FEIGHT',
      t2: 'No Commision Or Monthly Fee',
      t3: 'Save Hundreds of Dollars',
      t4: 'Over 4,000 Dedicated Staff',
      num : ''
    },
    {
      t1: 'WAREHOUSING',
      t2: 'Delivery 10-30 days',
      t3: 'No Commision Or Monthly Fee',
      t4: 'Save Hundreds of Dollars',
      t5: 'Over 2,000 Dedicated Staff',
      num : 1
    },
    {
      t1: 'AIR FREIGHT',
      t2: 'Delivery 3-4 Days',
      t3: 'Flat Rate For Each Shipment',
      t4: 'Delivery To Your Doorstep',
      t5: 'Charters And International Transportation',
      num : 2
    },
  ]

  const items = infos.map((coin)=>{
    return(
        <div className='boxed'>
        <div className={`box_img${coin.num}`} ></div>
          <div className='box_sub'>
            <h3>{coin.t1}</h3>
            <p>{coin.t2}</p>
            <p>{coin.t3}</p>
            <p>{coin.t4}</p>
            <p>{coin.t5}</p>
          </div>
        </div>
    )
})

  const resposive ={
    0:{
        items: 1, 
    },
    512:{
        items: 3
    }
}

  return (
     <>
       <AliceCarousel
            mouseTracking
            infinite
            autoPlayInterval={2000}
            animationDuration={2500}
            responsive={resposive}
            autoPlay
            items={items}
            disableButtonsControls
      />
     </>
  )
}

export default Slider