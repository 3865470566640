import Lottie from 'lottie-react'
import React from 'react'
import load from './asset/load.json'

function Loading() {
  return (
    <div className='load' >
        <Lottie animationData={load} className='loader' />
    </div>
  )
}

export default Loading