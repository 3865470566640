import { createSlice } from '@reduxjs/toolkit';



export const chatSlice = createSlice({
  name: 'chat',
  initialState :{
    chat: null,
  },
  reducers: {
    chat: (state, action) => {
      state.chat = action.payload;
    },
  }
});

export const { chat } = chatSlice.actions;

export const selectchat = (state) => state.chat.chat;

export default chatSlice.reducer;
