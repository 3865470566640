import React from 'react'
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css';
import { Favorite, Person, Sms } from '@mui/icons-material';
import blog1 from './img/blog1.jpg'
import blog2 from './img/blog2.jpg'
import blog3 from './img/blog3.jpg'
import blog4 from './img/fifimg.jpg'


 
function Slides() {

  const infos = [
    {
      bg: 'Quarta Decima Etquinta',
      t1: 'Claritas est etiam processus dynamicus sequitur the  consuetudium lectorum Mirum est',
      t2: '250',
      img: blog1,
      shw: '110'
    },
    {
      bg: 'Quarta Decima Etquinta',
      t1: 'Claritas est etiam processus dynamicus sequitur the  consuetudium lectorum Mirum est',
      t2: '250',
      img: blog2,
      shw: '110'
    },
    {
      bg: 'Quarta Decima Etquinta',
      t1: 'Claritas est etiam processus dynamicus sequitur the  consuetudium lectorum Mirum est',
      t2: '250',
      img: blog3,
      shw: '110'
    },
    {
      bg: 'Quarta Decima Etquinta',
      t1: 'Claritas est etiam processus dynamicus sequitur the  consuetudium lectorum Mirum est',
      t2: '250',
      img: blog4,
      shw: '110'
    },
  ]

  const items = infos.map((coin)=>{
    return(
      <div className='blogbox'>
          <img src={coin.img} alt='' />
          <div className='blogboxinner'>
            <div className='blogboxicons'>
              <div className='blogboxfsticons'>
                <Person/>
                <small>Md.Masud</small>
              </div>

              <div className='secicons'>
                <div className='blogboxfsticons'>
                  <Sms/>
                  <small>{coin.t2}</small>
                </div>

                <div className='blogboxfsticons'>
                  <Favorite/>
                  <small>{coin.shw}</small>
                </div>
              </div>
            </div>

          </div>
            <h3>{coin.bg}</h3>
            <p>{coin.t1}</p>
        </div>
    )
})

  const resposive ={
    0:{
        items: 1, 
    },
    512:{
        items: 3
    }
}

  return (
     <>
       <AliceCarousel
            mouseTracking
            infinite
            autoPlayInterval={2000}
            animationDuration={2500}
            responsive={resposive}
            autoPlay
            items={items}
            disableButtonsControls
      />
     </>
  )
}

export default Slides