import React, {useState, useEffect} from 'react';
import './App.css';
import {HashRouter as Router, Routes, Route} from 'react-router-dom'
import Home from './Home';
import About from './About';
import Contact from './Contact';
import Request from './Request';
import Popups from './Popups';
import Pdfviewer from './Pdfviewer';
import Loading from './Loading';

function App() {

  const [loading, setloading] = useState(false)


  useEffect(()=>{
    setTimeout(()=>{
      setloading(false)
    }, 4000)

    clearTimeout(setloading(true))
  },[])

  return (
    <>
      {
        loading ?
          <Loading/>
        :
        <div className="App">
          <Router>
            <Routes>
              <Route path='/' element={<Home/>}  />
              <Route path='/about' element={<About/>}  />
              <Route path='/contact' element={<Contact/>}  />
              <Route path='/request' element={<Request/>}  />
              <Route path='/tracker' element={<Popups/>}  />
              <Route path='/pdffile' element={<Pdfviewer/>}  />
            </Routes>
          </Router>
      </div>
      }
    </>
  );
}

export default App;
