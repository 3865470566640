import React from 'react'
import './map.css'
import { LocationOn } from '@mui/icons-material'

function Map() {
  return (
   <div className='mab_bod'>
        <h2>Location <LocationOn style={{color:'grey'}} /></h2>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '500px', width:'95%' }}>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d44223235.85211832!2d163.93798828124997!3d47.42808726171425!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54905c58a8ca6635%3A0x15e7df84c16844d5!2sGlobal%20Express%20U.S.%20LLC!5e0!3m2!1sen!2sng!4v1717341100653!5m2!1sen!2sng" 
        width="100%" 
        height="450" 
        style={{border:'none'}} 
        allowfullscreen="" 
        loading="lazy" 
        referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
   </div>
  )
}

export default Map