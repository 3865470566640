import React from 'react'
import Header from './Header'
import Bottom from './Bottom'
import './request.css'

function Request() {
  return (
    <div className='requests'>
      <Header/>
        <div className='request_body'>
          <h3>Request A Service</h3>
          <p>Reach out to us today for all your professional shipping needs</p>

          <div className='inp'>
            <p>Freight Type</p>
            <select>
              <option>choose a type</option>
              <option>Road Transportatio</option>
              <option>Sea Transportation</option>
              <option>Air Transportatio</option>
              <option>Ware housing</option>
            </select>
          </div>

          <div className='inp'>
            <p>Departure</p>
            <input/>
          </div>

          <div className='inp'>
            <p>Destination</p>
            <input/>
          </div>

          <div className='inp'>
            <p>Freight category</p>
            <select>
              <option>EXW</option>
              <option>FCA</option>
              <option>CPT</option>
              <option>CIT</option>
            </select>
          </div>

          <div className='inp'>
            <p>Gross Weight(KG)</p>
            <input/>
          </div>

          <div className='inp'>
            <p>Your Full Name</p>
            <input/>
          </div>

          <div className='inp'>
            <p>You Email Address</p>
            <input/>
          </div>

          <div className='inp'>
            <p>Special Instruction</p>
            <textarea/>
          </div>

          <button>REQUEST QUOTE</button>
        </div>
      <Bottom/>
    </div>
  )
}

export default Request