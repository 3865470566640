import React from 'react'
import './banner.css'
import ban from './img/ban.mp4'
import ban1 from './img/bbn.gif'
import ReactPlayer from 'react-player';

function Banners() {
  return (  
    <div  className='banvid'>
      <video className='vid' src={ban} autoPlay muted loop  />
     
     <div className='gif'>
        <img src={ban1} alt='' />
     </div>
    </div> 
  )
}

export default Banners