import React, { useEffect, useRef, useState } from 'react'
import Banners from './Banners'
import { Edit, LocationOn, Public} from '@mui/icons-material'
import { Link, useNavigate } from 'react-router-dom'
import Bottom from './Bottom'
import { db } from './firebase'
import { useDispatch } from 'react-redux'
import { info } from './features/infoSlice'
import Map from './Map'
import Slides from './Slide'
import Slider from './Slider'
import im1 from './img/1078005.png'
import imlo from './img/lo.jpg'
import imdl from './img/dl.jpg'
import imh from './img/house.png'
import imcar from './img/caricon.svg'
import im2 from './img/icon2.png'


function Homebody() {

  const [input, setinput] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [err, seterr] = useState('')

    const [userref, setuserref] = useState(null)

    
    const [ids, setids] = useState([])

    useEffect(()=>{
        db.collection('userchat').onSnapshot((snapshot)=>{
            setids(
            snapshot.docs.map((doc)=>({
              id : doc.id,
              data : doc.data()
            }))
          )
        })
  },[])   

  
  useEffect(()=>{
      const arr =[]
    ids?.map(({id,data:{trd, trackid,companylocation, time,map1, map2, status,sendersname, senderslocation, sendersemail, sendername, sec, remarks,recieversname, recieverslocation, recieversemail, quantity,packageweight,country, packageupdate, packagename, frt, fst, img, packagedescription, packageloaction, amount, servicetype, shippeddate, phonenumber, timestamp}})=>{
        return arr.push({          
        country : country,
        frt: frt,
        fst: fst,
        amount: amount,
        servicetype: servicetype,
        shippeddate: shippeddate,
        phonenumber: phonenumber,
        timestamp: timestamp,
        img: img,
        map1: map1,
        map2: map2,
        packagedescription : packagedescription,
        packageloaction : packageloaction,
        packagename : packagename,
        packageupdate : packageupdate,
        packageweight : packageweight,
        quantity : quantity,
        recieversemail : recieversemail,
        recieverslocation : recieverslocation,
        recieversname : recieversname,
        remarks : remarks,
        sec : sec,
        sendername : sendername,
        sendersemail : sendersemail,
        senderslocation : senderslocation,
        sendersname : sendersname,
        status : status,
        time : time,
        trackid : trackid,
        companylocation : companylocation,
        trd : trd
        })
    })
    setuserref(arr.find(item => item.trackid === input))
  }, [ids, input])



  


  const track =async()=>{
    if(input ){
      dispatch(
        info({
          tracker : userref
        })
        )
      
        if(userref){
          navigate('/tracker') 
        }else{
          seterr('Track id not found')
        }
    }else{
      seterr('Enter Track id please')
    }
  }






  return (
    <div className='homebody'>
      <div className='head'>
        <Banners/> 
        <div className='glass'>
          <div className='glassbod'>
            <Public id='wrld' />
            <div>
            <p>G.C.C. </p>
            <small>Globalexpress Courier Company</small>
            </div>
          </div>
         </div>

        <div className='track_field'>
          <h3>Please Enter Your Tracking Number To View Your Parcel</h3>
          <div className='track_input'>
            <small>{err}</small>
            <input onChange={(e)=>setinput(e.target.value)}
             placeholder='Put in your tracking id here' />
              <div onClick={track} className='request'>
                <p>Track Package</p>
                <LocationOn/>
              </div>

              <div className='total' >
              <div className='total1' >
                <h3>10,000</h3>
                <h3>DELIVERED PACKAGES</h3>
              </div>
              <div className='total2'>
                <h3>4,500</h3>
                <h3>SATISFIED CLIENTS</h3>
              </div>
              </div>
              
          </div>
        </div>
      </div>


      <div className='mid1 '>
        <div style={{zIndex:'1000'}} >
        <p>WHAT WE PROVIDE FOR CLIENTS AMONG OTHERS</p>
        <h1>QUICK CARGO SERVICES</h1>
        </div>

        <div className='blog wiblog'>
          <Slider/>
        </div>

      </div>




      <div className='mid2'>
        <div className='mid2_box1'>
          <p>Safe & Affordable Cargo Services</p>
          <h3>WE OFFER QUICK CARGO SHIPMENT FACILITIES</h3>
          <small>Our Services are world class, cheap and efficient 
          and we pride our selves in integrity and confidentiality</small>

          <Link to='/request' className='request'>
            <p>REQUEST A QUOTE</p>
            <Edit/>
          </Link>
        </div>

        <div className='mid2_box2'>
          <h3>PACKAGED STORAGED</h3>
            <strong>Professional Productize</strong>
            <p>We also assure you of quality storage whilst your parcel is with us</p>
            <div className='transparent'></div>
        </div>

        <div className='mid2_box3'>
          <h3>COMPANY CONTACT</h3>
            <strong>Professional Productize</strong>
            <p>You are sure that we bind to our contract and your items gets to you in record time</p>
            <div className='transparent'></div>
        </div>

        <div className='mid2_box4'>
          <h3>TRACK PACKAGE</h3>
            <strong>Professional Productize</strong>
            <p>You can also track your package on the go from the comfort of any device</p>
            <div className='transparent'></div>
        </div>
      </div>


      
    <div className='gallery'>
      <p>See Our News</p>
      <h1>LATEST BLOG</h1>
      
      <div className='blog'>
        <Slides/>
      </div>
    </div>



    <div className='gallery_bod'>

      <div className='gallery_box'>
        <img src={im1} alt='' />
        
        <div  className='boxp'>
          <h3>Air Freight</h3>
          <p>The company has vessels at it's disposals 
        to help transfer goods across continents 
        anytime the need arises.</p>
        </div>
      </div>



      <div className='gallery_box'>
        <img src={im2} alt='' />
        
        <div  className='boxp'>
          <h3>Ocean Freight</h3>
          <p>The company has vessels at it's disposals 
          to help transfer goods across continents 
          anytime the need arises.</p>
        </div>
      </div>


      <div className='gallery_box'>
        <img src={imcar} alt='' />
        
        <div  className='boxp'>
          <h3>Ground Freight</h3>
          <p>Our door-to-door service is superb for quick
           same day delivery and we actually deliver on time.</p>
        </div>
      </div>


      <div className='gallery_box'>
        <img src={imh} alt='' />
        
        <div  className='boxp'> 
          <h3>Warehousing</h3>
          <p>You don't need to worry if your goods will ever get 
          damaged while with us. We provide decent 
          warehousing for all shipments until they are delivered.</p>
        </div>
      </div>


      <div className='gallery_box'>
        <img src={imdl} alt='' />
        
        <div  className='boxp'>
          <h3>Delivery</h3>
          <p>Door to door delivery is our pride.
           We are happy when we move an item to our
          customer and with a smile on their face 
          receiving their items.</p>
        </div>
      </div>


      <div className='gallery_box'>
        <img src={imlo} alt='' />
        
        <div className='boxp'>
          <h3>Tracking</h3>
          <p>Want to know where your item is at 
            any given point in time? No problem. 
            We have provided you with an easy way 
            of tracking your items in real time.
          </p>
        </div>
      </div>


    </div>

    


    <div className='benf'>
        <p>Why you should consider using our services</p>
        <strong>COMPANY BENEFITS</strong>

        <div className='benf_body'>
          <div className='benf_bod1'>
            <p>Real Time Track</p>
            <small>The company has built a very intuitive
            tool to help you track your consignments in 
            realtime from any device that can acess 
            the internet</small>
          </div>

          <div className='benf_bod1'>
            <p>Affordable Rates</p>
            <small>All our pricing are considerate to our customers 
                  as we know humans are very sensitive to prices and 
                  it is also very competitive in the market place</small>
          </div>

          <div className='benf_bod1'>
            <p>World class Storage</p>
            <small>Our storage facilities are world 
              class and you don't have to worry if 
              your item will get damaged while in 
              transit. We provide world class 
              storage and handling.</small>
          </div>
           
       </div>
       </div>


       

      
    <Map/>



    <Bottom/>

    </div>

  )
}

export default Homebody