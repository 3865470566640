import React from 'react'
import './about.css'
import Header from './Header'
import Bottom from './Bottom'

function About() {
  return (
    <div className='about'> 
      <Header/>
 
        <div className='about_body'>
        <div className='abt'>
        <div className='abtshd'></div>
        </div>
        </div>

        <div className='about_box'>
        <h1>About Us</h1>

        <span>Get to know that your logistics needs are in good hands.</span>
        <span>  We have been professionally handling logistics
           needs for all our customers for 10 years and we 
           have proven to be a leader in the industry. Our 
           services are not restricted to any particular state, 
           region or country as we are a global company and 
           we move goods world wide.
            Our people are some of the best 
            hands in the industry. When you use 
            our services, you are sure of getting 
            the best customer support. You get your 
            goods delivered as at promise and you can 
            track the movement of your goods in real time.</span>
      </div>

      <div className='about_box'>
        <div className='sec_box'>
          <div className='sec_box_sub'>
          <h3>OUR GLOBAL VISION</h3>
          <p>From the onset, we set out to be a global 
          provider of all forms of logistics needs and 
          we have been expanding in that regards. Our 
          vision is to help you effortlessly move 
          goods from state to state, region to region, 
          country to country and continent to continent 
          through sea, air, land and others.
          Our warehousing facilities are top 
          notch and your goods are always secured 
          until they are delivered to you</p>
          </div>
          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMnr65pZDm9a2J3TZLQTZ_eFSj3pkVldZ_CQ&usqp=CAU" alt='' />
        </div>

        <div className='trd_box'>
        <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzMWq7PjAVdRcEnH9l-w-k9GgMJYHFfeaJsw&usqp=CAU' alt='' />
          <div className='sec_box_sub'>
          <h3>WE ARE GROWING TOGETHER</h3>
          <p>Our core business relation is about people 
          and we put people at the core of our business. 
          Your growth is our priority. We enable businesses 
          buy providing top notch logistics services. You 
          want quality logistics services, try us today.</p>
        </div>
          </div>
      </div>

      <div className='trd_box'>
        <div className='trd_box2'>
          <img src='https://media.istockphoto.com/id/609696404/photo/i-dominated-the-business-world.webp?b=1&s=170667a&w=0&k=20&c=v1r4UXecc2Rvj07iLXCNbym7jTksNXZiWBg1JidMOCU=' alt='' />
          <h3>JONATHAN GRANT</h3>
          <p>CTO</p>
        </div>

        <div className='trd_box2'>
          <img src={'https://thumbs.dreamstime.com/b/profile-picture-caucasian-male-employee-posing-office-happy-young-worker-look-camera-workplace-headshot-portrait-smiling-190186649.jpg'} alt='' />
          <h3>ALLAN STEWERT</h3>
          <p>CUSTOMER RELATIONS</p>
        </div>

        <div className='trd_box2'>
          <img src={'https://thumbs.dreamstime.com/b/close-up-headshot-portrait-smiling-young-caucasian-businessman-formal-suit-look-camera-posing-office-profile-picture-288411750.jpg'} alt='' />
          <h3>BRADON CASTILO</h3>
          <p>OPERATIONS HEAD</p>
        </div>

        <div className='trd_box2'>
          <img src={'https://thumbs.dreamstime.com/b/boss-man-looking-camera-smiling-young-businessman-banker-beard-photo-close-up-portrait-284678755.jpg'} alt='' />
          <h3>ALLAN STEWERT</h3>
          <p>CEO</p>
        </div>
      </div>

      <Bottom/>
    </div>
  )
}

export default About